// react
import React from "react";

// components
import AboutUsSection from "./AboutUsSection";
import { Grid } from "semantic-ui-react";


// component
function AboutUsInfo() {
    // template
    return (
        <Grid className="section" relaxed>
            <Grid.Row columns={1} centered>
                <h2>
                    zadaj – vyhľadaj – užívaj
                </h2>
            </Grid.Row>

            <Grid.Row columns={3} centered>
                <AboutUsSection
                    icon=""
                    header="Kto sme?"
                    text="ActivityPoint je platforma, ktorej cieľom je ponúknuť aktivity pod 1 strechou 24/7/365 na online mape na webe www.activitypoint.xyz"
                />
                <AboutUsSection
                    icon=""
                    header="Čo chceme dosiahnuť?"
                    text="Naším zámerom je na pár kliknutí vytvoriť sociálnu sieť platených/bezplatných aktivít a to na konkrétnom mieste, v konkrétnom čase, v jednotlivých kategóriách. Na aktivity nielenže navigujeme a o aktivitách podrobne informujeme (vrátane preklikov na sociálne siete zadávateľa aktivity), ale aktivity umožňujeme aj zdieľať."
                />
                <AboutUsSection
                    icon=""
                    header="Prečo?"
                    text="V súčasnosti ešte stále neexistuje jednoduché riešenie v oblasti vyhľadávania dostupných aktivít. Aktivity sú dostupné vo forme klikateľných bodov na mape určené k zobrazeniu alebo predaju bežnému užívateľovi, ktorý aktivity vyhľadáva. Platforma vznikla ako digitálna odpoveď na tieto potreby, ktoré šetria užívateľom čas a zadávateľom aktivít extra peniaze vynaložené na marketing."
                />
            </Grid.Row>


            <Grid.Row columns={2} centered 
                style={{ marginTop: '3rem' }}
            >
                <Grid.Column
                    mobile={12}
                    tablet={6}
                    computer={6}
                >
                    <img src="/images/icons/design_dot.png" alt="Dot" />
                    <br />

                    <h2>
                        O nás
                    </h2>
                    <p>
                        ActivityPoint bol založený roku 2017, potom, čo tvorca projektu ochorel a to ho stoplo od pokračovania v jeho dennej pracovnej rutine, ktorej súčasťou bolo aj poskytovanie tréningov. Rozhodol sa preto, že vytvorí webovú platformu aby v nej umožnil prezentáciu aktívnych miest v okolí tým, ktorí aktivity vyhľadávajú a zároveň poskytne nástroj ľuďom, ktorí služby v oblasti aktivítposkytujú. 
                    </p>
                </Grid.Column>

                <Grid.Column
                    mobile={12}
                    tablet={6}
                    computer={6}
                    textAlign={"center"}
                    style={{height: 'auto'}}
                >
                    <div style={{minHeight: '16rem', width: '100%!important', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img src="/images/logos/ap_logo.svg" alt="Logo"
                            width="300"
                         />     
                    </div>
                </Grid.Column>
            </Grid.Row>


            <Grid.Row columns={2} centered 
                style={{ marginTop: '3rem' }}
            >
                <Grid.Column
                    mobile={12}
                    tablet={6}
                    computer={6}
                >
                    <img src="/images/icons/design_dot.png" alt="Dot" />
                    <br />

                    <h2>
                        Hlavná myšlienka 
                    </h2>
                    <p>
                        Aby užívateľ ušetril čas, ktorý by inak strávil pracným vyhľadávaním, prípadným rezervovaním platených aktivít, o ktoré má záujem. Aby zadávateľ aktivity dostal do rúk nový marketingový nástroj na prezentáciu a predaj svojich aktivít. Aby mesto/obec dostalo možnosť funkčne odprezentovať možnosti svojho regiónu.
                    </p>
                </Grid.Column>

                <Grid.Column
                    mobile={12}
                    tablet={6}
                    computer={6}
                >
                    <img src="/images/icons/design_dot.png" alt="Dot" style={{opacity: 0}} />

                    <h2>
                        Pridaná hodnota
                    </h2>
                    <p>
                        Jedinečné (1), funkčné a férové riešenie na trhu s aktivitami pre užívateľov, zadávateľov aktivít, zároveň pre samosprávy.  
                    </p>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2} 
                style={{ marginTop: '4rem' }}
                centered
            >
                <Grid.Column
                    mobile={12}
                    tablet={6}
                    computer={6}
                    textAlign={"center"}
                    style={{height: 'auto'}}
                >
                    <img src="/images/public/about/ap-about-us-2.jpg" alt="Connect" 
                        style={{
                            borderRadius: '4px'
                        }} 
                    />
                </Grid.Column>

                <Grid.Column
                    mobile={12}
                    tablet={6}
                    computer={6}
                    style={{marginTop: '1rem'}}
                >
                    <h2>
                        Konkurencia
                    </h2>
                    <p>
                        Unikátný projekt zabezpečuje prvú pozíciu na trhu v tomto segmente. Ponúkame platformu, ktorá kompletne zastreší potreby tak ako užívateľa (ktorý aktivity vyhľadáva – platené/bezplatné); tak aj zadávateľa aktivity (ktorý aktivity poskytuje). 
                    </p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default AboutUsInfo;
